import { registerApplication, pathToActiveWhen, start } from 'single-spa';
import { APPROVE_GATEWAY_API } from './config/index';

const RE_WITHOUT_SLASHES = /^#!?\w*\/([\w-]+)\/?.*$/;
// match paths but match slashes within the part we want
const RE_WITH_SLASHES = /^#!?\w*\/([\w-/]+)\/?.*$/;

const registerApp = (app) => {
  registerApplication({
    name: app,
    app: () => System.import(app),
    activeWhen: () => {
      return (
        !hashName('authorize') &&
        !pathMatch('/authorize') &&
        !hashName('sign-in') &&
        !pathMatch('/sign-in') &&
        !hashName('sign-out') &&
        !pathMatch('/sign-out') &&
        !hashName('back-office') &&
        !pathMatch('/back-office')
      );
    },
    customProps: {
      isTipalti: false,
      isApprove: true,
      isSideBar: true,
      httpConfig: {
        baseUrl: APPROVE_GATEWAY_API,
      },
    },
  });
  start();
};

const hashName = (name: string, matchSlashes = false): boolean => {
  if (!matchSlashes) {
    const matches = window.location.hash.match(RE_WITHOUT_SLASHES) || [];
    return matches.indexOf(name) === 1; // should be the first match after the whole string
  } else {
    const matches = window.location.hash.match(RE_WITH_SLASHES) || [];
    return matches.indexOf(name) === 1 || (matches.length > 1 && matches[1].startsWith(name)); // should be the first match after the whole string
  }
};

const pathMatch = (path: string): boolean => {
  const activeWhen = pathToActiveWhen(path);

  const isMatch = activeWhen(window.location);

  return isMatch;
};

export default registerApp;

// @ts-ignore
window.logSidebarApi = () => {
  console.log(APPROVE_GATEWAY_API);
};

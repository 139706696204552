import { registerApplication, start, getAppStatus } from 'single-spa';
import { constructApplications, constructRoutes, constructLayoutEngine } from 'single-spa-layout';
import { HTMLLayoutData } from 'single-spa-layout/dist/types/isomorphic/constructRoutes';
import layout from './layout.html';
import loader from './loader.html';
import bodymovin from 'lottie-web';
import animationJson from '../public/Tipalti_Loader.json';
import PubSub from 'pubsub-js';
import registerApp from './registerApplication';

const publish = (event, data) => PubSub.publish(event, data);
const subscribe = (event, callback) => PubSub.subscribe(event, callback);
let loading: boolean = false;
const NAVBAR_APP = '@tipalti-aphub/navbar';

const initLoaderAnimation = () => {
  setTimeout(() => {
    const animation = bodymovin.loadAnimation({
      container: document.getElementById('approve-loader'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationJson,
    });
    animation.play();
  });
};
initLoaderAnimation();

const layoutData: HTMLLayoutData = {
  props: {
    isTipalti: false,
    isApprove: true,
    isSideBar: true,
    shellCommunication: {
      publish,
      subscribe,
    },
  },
  loaders: {
    fullPage: loader,
  },
};

const routes = constructRoutes(layout, layoutData);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach((app) => {
  if (app.name === NAVBAR_APP) {
    return;
  }

  registerApplication(app);
});
layoutEngine.activate();
start();

const toggleSideBar = (show: boolean) => {
  const el = document.getElementById('root-app-container');
  if (!el) return;
  el.style.flexDirection = show ? 'row' : 'column';
};

window.addEventListener('single-spa:app-change', (evt: any) => {
  toggleSideBar(
    evt.detail.newAppStatuses[NAVBAR_APP] && evt.detail.newAppStatuses[NAVBAR_APP] === 'MOUNTED'
  );
  if (
    evt.detail.newAppStatuses[NAVBAR_APP] &&
    evt.detail.newAppStatuses[NAVBAR_APP] === 'LOAD_ERROR' &&
    loading
  ) {
    toggleLoader();
  }
});

window.addEventListener('APPROVE_LOADER', (evt: any) => {
  const { isLoading } = evt.detail;
  const sideBarEl = document.getElementById('approve-navbar-container');
  sideBarEl.style.display = isLoading ? 'none' : 'block';
});

window.addEventListener('APPROVE_NAV_LOADER', (evt: any) => {
  const { isLoading } = evt.detail;
  const sideBarEl = document.getElementById('approve-navbar-container');
  sideBarEl.style.display = isLoading ? 'none' : 'block';
  if (isLoading === loading) {
    return;
  }
  toggleLoader();
});

window.addEventListener('HIDE_SIDEBAR', (evt: any) => {
  const sideBarEl = document.getElementById('approve-navbar-container');
  sideBarEl.style.display = 'none';
});

const toggleLoader = () => {
  loading = !loading;
  const mainContainer = document.getElementById('root-app-container');
  if (loading) {
    const loaderContainer = document.createElement('div');
    loaderContainer.setAttribute('id', 'loader-container');
    loaderContainer.innerHTML = loader;
    mainContainer.append(loaderContainer);
    initLoaderAnimation();
  } else {
    const elem = document.querySelector('#loader-container');
    if (elem) {
      mainContainer.removeChild(elem);
    }
  }
};

//remove the loader in case the 'APPROVE_NAV_LOADER' event wouldn't be triggered by the sidebar app
const loaderTimeout = () => {
  setTimeout(() => {
    if (loading) {
      toggleLoader();
    }
  }, 10000);
};

subscribe('SHOW_SIDEBAR', async (eventName, withNewSidebar: boolean) => {
  if (getAppStatus(NAVBAR_APP) || !withNewSidebar) {
    return;
  }

  toggleLoader();
  registerApp(NAVBAR_APP);
  loaderTimeout();
});

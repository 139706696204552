import { merge } from 'lodash';
import all from './env/all';
import production from './env/production';
import prodeu from './env/prodeu';
import staging from './env/staging';
import development from './env/development';
import sandbox from './env/sandbox';
import local from './env/local';
import rc from './env/rc';

export const currentEnv = process.env.REACT_APP_ENV || 'development';

const environments = {
  production,
  staging,
  development,
  sandbox,
  local,
  prodeu,
  rc,
};

let merged = merge({}, all, environments[currentEnv]);

export default merged;

export const isProduction = currentEnv === 'production';
export const isSandbox = currentEnv === 'sandbox';
export const isDevelopment =
  currentEnv !== 'production' && currentEnv !== 'sandbox' && currentEnv !== 'prodeu';

const envMap = new Map([
  ['production', ''],
  ['prodeu', ''],
  ['rc', 'rc.'],
  ['local', 'dev.'],
  ['development', 'dev.'],
  ['sandbox', 'sandbox.'],
]);

export const APPROVE_GATEWAY_API = merged.api.url.replace(
  '//',
  `//aphub.${envMap.get(currentEnv)}`
);
